import { CustomIcon } from '@/components/atoms/icons/types';

const GMC: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84516 14.1963H2.07012C1.83345 14.1963 1.62654 14.4919 1.62654 14.6695V17.2127C1.62654 17.745 1.77433 17.8043 2.1588 17.8043H7.41992C8.27753 17.8043 8.21841 17.3016 8.21841 16.7987H5.35001V15.2019H9.84516V17.8043C9.84516 19.0168 8.60309 19.4013 8.12995 19.4013H1.6561C0.650489 19.4013 0 18.5141 0 17.8928V14.1665C0 13.4865 0.828048 12.5991 1.6561 12.5991H5.75063H9.84516V14.1963ZM21.8126 19.4013H20.2156V14.4032L17.2809 19.4013H15.8317L12.9213 14.2554V19.4013H11.3243V12.5993H13.8974L16.5415 17.4198L19.3287 12.5993H21.8128L21.8126 19.4013ZM32 14.1963H25.2084C24.9717 14.1963 24.7646 14.4919 24.7646 14.6695V17.2127C24.7646 17.745 24.9126 17.8043 25.2969 17.8043H31.9998V19.4013H24.7942C23.7883 19.4013 23.1376 18.5141 23.1376 17.8928V14.1665C23.1376 13.4865 23.9661 12.5991 24.7942 12.5991H31.9998V14.1963H32Z"
      fill="currentColor"
    />
  </svg>
);

export default GMC;
