import { CustomIcon } from '@/components/atoms/icons/types';

const Fiat: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.2284 13.8676C29.2284 11.8476 28.9124 10.1223 28.2577 8.59294C27.7684 7.44894 26.9457 6.48894 25.8777 5.81694C23.2244 4.15161 20.2084 3.17294 17.159 2.98761C16.799 2.96628 16.4324 2.95294 16.0684 2.95294H16.0404C15.6777 2.95294 15.311 2.96628 14.951 2.98761C11.9017 3.17294 8.88704 4.15161 6.23104 5.81694C5.16437 6.48894 4.34037 7.44894 3.85104 8.59561C3.19904 10.1236 2.88037 11.8476 2.88037 13.8676C2.88037 14.9569 2.97104 16.1196 3.15637 17.4209C3.52037 19.9889 4.4737 22.6716 5.38304 24.5183C5.57637 24.9129 5.7297 25.1796 6.0377 25.4516C6.46704 25.8329 6.84704 25.9276 6.98704 25.8449C7.0977 25.7809 7.16037 25.4449 7.13904 24.9529C7.11904 24.5223 7.03504 24.2423 6.90704 23.8329C6.1657 21.4556 5.61637 18.9036 5.26704 16.4956C4.7537 12.9436 4.77237 9.74761 5.53637 7.79961C5.6377 7.54361 5.74704 7.35428 5.83237 7.23161C5.9137 7.11161 5.9737 7.05028 5.9737 7.05028C7.55104 5.47294 11.811 4.68094 16.0724 4.68094C20.3337 4.68094 24.595 5.47294 26.1724 7.05028C26.1724 7.05028 26.231 7.11161 26.3137 7.23161C26.3964 7.35428 26.507 7.54361 26.6084 7.79961C27.3724 9.74761 27.3924 12.9436 26.8777 16.4956C26.535 18.8649 25.9977 21.3729 25.275 23.7169C25.1204 24.2169 25.0204 24.5263 25.0017 24.9489C24.979 25.4423 25.0417 25.7796 25.155 25.8449C25.2924 25.9276 25.671 25.8343 26.0964 25.4569C26.3404 25.2409 26.4644 25.0449 26.615 24.7476C27.5537 22.9009 28.571 20.1009 28.9537 17.4209C29.139 16.1196 29.2284 14.9569 29.2284 13.8676Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.8723 27.8755C22.8457 27.7768 22.4057 27.8475 21.895 27.9595C21.7777 27.9848 21.4123 28.0702 21.051 28.1702C20.7257 28.2582 20.4057 28.3302 20.2537 28.3595C19.0003 28.6035 17.5843 28.7395 16.091 28.7395H16.0537C14.5617 28.7395 13.1457 28.6035 11.8923 28.3595C11.7563 28.3328 11.447 28.2662 11.1443 28.1822C10.8057 28.0915 10.459 28.0142 10.3377 27.9848C9.779 27.8582 9.275 27.7688 9.24567 27.8755C9.21234 27.9982 9.46167 28.2595 9.78167 28.5035C9.85634 28.5608 10.043 28.6902 10.259 28.7955C10.4577 28.8955 10.687 28.9675 10.775 28.9968C12.2417 29.4702 13.9097 29.6675 16.0403 29.6675H16.0697C18.1577 29.6675 19.803 29.4768 21.2497 29.0222C21.7457 28.8662 22.0377 28.7408 22.3897 28.4622C22.683 28.2315 22.9043 27.9915 22.8723 27.8755Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.7491 11.7739H23.4385L23.0705 22.7885H21.5585L21.6131 11.7859V11.7739H20.2811V10.3365L24.7491 10.3192V11.7739ZM20.4558 22.1512C20.4505 22.3739 20.4465 22.5859 20.4438 22.7885H19.0238V22.7699C19.0238 22.7245 19.0105 18.2245 18.8385 17.1632L18.8371 17.1525H16.9425L16.9291 22.7819H15.4718L15.3265 10.3365H18.9771C20.6291 13.5245 20.5211 18.9245 20.4558 22.1512ZM14.3331 22.7885H12.7878L12.5451 10.3232L14.3691 10.3192L14.3331 22.7885ZM11.8625 11.8059H10.1131V15.6592H11.4705V17.1245H10.1131L10.1358 22.7899L8.63579 22.7739L8.27979 10.3232L11.8625 10.3459V11.8059ZM25.9971 8.18987C25.9091 7.9632 25.8131 7.80053 25.7438 7.69787C25.6811 7.60987 25.6398 7.56453 25.6398 7.56453C24.2131 6.1352 20.2158 5.36053 16.0718 5.36053C11.9305 5.36053 7.93179 6.1352 6.50513 7.56453C6.50513 7.56453 6.46246 7.60987 6.40113 7.69787C6.33179 7.80053 6.23579 7.9632 6.14779 8.18987C5.43846 9.99653 5.40779 13.0232 5.90646 16.4592C6.33579 19.4285 7.08113 22.6232 8.12513 25.3939C8.29713 25.8579 8.45313 26.0325 8.83046 26.3272C9.88246 27.0699 10.9678 27.4765 12.0811 27.6979C13.2771 27.9299 14.6291 28.0605 16.0545 28.0605H16.0718H16.0905C17.5171 28.0605 18.8678 27.9299 20.0651 27.6979C21.1771 27.4765 22.2625 27.0699 23.3145 26.3272C23.6918 26.0325 23.8478 25.8579 24.0211 25.3939C25.0651 22.6232 25.8091 19.4285 26.2398 16.4592C26.7371 13.0232 26.7065 9.99653 25.9971 8.18987Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5435 14.2101C18.3502 13.1301 18.1168 12.3008 17.8515 11.7434L17.8488 11.7381H17.8208C17.7288 11.7381 16.9182 11.7288 16.9088 11.7288H16.8982L16.8608 15.7274H18.7662L18.7648 15.7141C18.7635 15.7061 18.6888 15.0168 18.5435 14.2101Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.9993 0.698362C24.5753 0.698362 31.5539 7.67303 31.5579 16.249C31.3886 16.001 31.1673 15.781 30.8873 15.577C30.0633 14.985 29.6379 12.925 29.5566 12.129L29.5513 12.121C29.6073 12.6797 29.6353 13.261 29.6353 13.8677C29.6353 14.9757 29.5419 16.1584 29.3566 17.4784C28.8046 21.3437 26.9353 25.481 25.8326 26.753C25.8326 26.753 25.7633 26.8277 25.7619 26.829L25.5139 27.0917L25.5019 27.0997C22.8179 29.2944 20.3499 30.073 16.0686 30.073H16.0406C11.7593 30.073 9.28993 29.2944 6.60593 27.0997L6.59527 27.0917C6.59527 27.0917 6.3406 26.8224 6.33927 26.821C6.33927 26.821 6.26727 26.741 6.27793 26.7544C5.17793 25.4864 3.3046 21.3437 2.75393 17.4784C2.56593 16.1584 2.47527 14.9757 2.47527 13.8677C2.47527 13.297 2.5166 12.769 2.56727 12.2424L2.55127 12.325C2.4726 13.1197 2.23393 14.449 1.35927 14.965C0.995268 15.1797 0.667268 15.5197 0.444601 16.0064C0.579268 7.54369 7.5046 0.698362 15.9993 0.698362ZM15.9993 0.258362C7.17793 0.258362 -0.000732422 7.43703 -0.000732422 16.2597C-0.000732422 25.081 7.17793 32.2584 15.9993 32.2584C24.8219 32.2584 31.9993 25.081 31.9993 16.2597C31.9993 7.43703 24.8219 0.258362 15.9993 0.258362Z"
      fill="currentColor"
    />
  </svg>
);

export default Fiat;
