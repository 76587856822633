import { CustomIcon } from '@/components/atoms/icons/types';

const Chevrolet: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.7529 14.5934L27.5743 18.9581H20.5118V21.1949H11.6329V18.9581H2.24707L4.42575 14.5934H11.6329V12.3604H20.5118V14.5934H29.7529ZM31.846 13.2243C32.0542 13.4963 32.0267 13.8211 31.9085 14.0579L28.9441 19.9966L28.4402 19.7451L31.4046 13.8064C31.4982 13.619 31.3819 13.4671 31.145 13.4671H21.6381V11.663C21.6381 11.4261 21.4461 11.2341 21.2092 11.2341H10.9355C10.6986 11.2341 10.5065 11.4261 10.5065 11.663V13.4671H4.15809C3.92118 13.4671 3.6533 13.619 3.55976 13.8064L0.595376 19.7451C0.501835 19.9325 0.618059 20.0844 0.85497 20.0844H10.5065V21.8923C10.5065 22.1292 10.6986 22.3213 10.9355 22.3213H21.2092C21.4461 22.3213 21.6381 22.1292 21.6381 21.8923V20.0844H27.8419C28.0788 20.0844 28.3467 19.9325 28.4402 19.7451L28.9441 19.9966C28.8333 20.2186 28.6468 20.3761 28.4726 20.4749C28.2946 20.5758 28.0736 20.6475 27.8419 20.6475H22.2013V21.8923C22.2013 22.4402 21.7571 22.8844 21.2092 22.8844H10.9355C10.3876 22.8844 9.94338 22.4402 9.94338 21.8923V20.6475H0.85497C0.630547 20.6475 0.343584 20.575 0.153981 20.3272C-0.054168 20.0551 -0.0267126 19.7304 0.0915028 19.4935L3.05588 13.5549C3.16669 13.3329 3.35322 13.1753 3.52736 13.0766C3.7054 12.9756 3.92641 12.9039 4.15809 12.9039H9.94338V11.663C9.94338 11.1151 10.3876 10.6709 10.9355 10.6709H21.2092C21.7571 10.6709 22.2013 11.1151 22.2013 11.663V12.9039H31.145C31.3695 12.9039 31.6564 12.9764 31.846 13.2243Z"
      fill="currentColor"
    />
  </svg>
);

export default Chevrolet;
