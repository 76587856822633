import { CustomIcon } from '@/components/atoms/icons/types';

const Nissan: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.59048 12.4808C6.25397 7.62367 10.8381 4.36018 16.0064 4.36018C21.1746 4.36018 25.7651 7.62367 27.4222 12.4808L27.4349 12.5189H31.073V12.0808L29.5619 11.903C28.6333 11.7956 28.4338 11.3866 28.1816 10.8696L28.1778 10.8618L28.1143 10.7348C25.9302 6.08716 21.1746 3.07764 16.0064 3.07764C10.8317 3.07764 6.08254 6.08716 3.89841 10.7475L3.83492 10.8745L3.83108 10.8823C3.57895 11.3993 3.37941 11.8083 2.4508 11.9157L0.939684 12.0935V12.5316H4.57143L4.59048 12.4808Z"
      fill="currentColor"
    />
    <path
      d="M4.63492 14.5951V18.3221H3.59365L0.825397 15.5284V18.3221H0V14.5951H1.06032L3.80952 17.376V14.5951H4.63492Z"
      fill="currentColor"
    />
    <path
      d="M7.28254 14.5951H6.45714V18.3221H7.28254V14.5951Z"
      fill="currentColor"
    />
    <path
      d="M12.273 17.5411C12.3111 17.5411 12.4127 17.5411 12.4381 17.5348C12.654 17.4967 12.7429 17.3316 12.7429 17.1792C12.7429 17.0205 12.6603 16.8427 12.419 16.811C12.4 16.8046 12.3048 16.8046 12.2667 16.8046H10.146C10.0762 16.8046 9.91746 16.8046 9.80317 16.7919C9.1746 16.703 8.88889 16.1697 8.88889 15.7126C8.88889 15.1538 9.26984 14.7157 9.81587 14.6395C9.89841 14.6268 10 14.6205 10.1333 14.6205H13.327V15.3697H10.1841C10.0635 15.3697 10.0063 15.3761 9.95556 15.3888C9.77778 15.4395 9.71429 15.5919 9.71429 15.7062C9.71429 15.8332 9.77778 15.9856 9.94921 16.03C9.9873 16.0427 10.0952 16.0491 10.1905 16.0491H12.3683C12.4127 16.0491 12.5143 16.0491 12.6349 16.0618C13.2508 16.1189 13.5746 16.6649 13.5746 17.1729C13.5746 17.6681 13.2635 18.2332 12.5841 18.2967C12.546 18.303 12.3492 18.303 12.3238 18.303H8.99048V17.5411H12.273Z"
      fill="currentColor"
    />
    <path
      d="M18.4762 17.5411C18.5206 17.5411 18.6222 17.5411 18.6476 17.5348C18.8571 17.4967 18.9524 17.3316 18.9524 17.1792C18.9524 17.0205 18.8698 16.8427 18.6286 16.811C18.6095 16.8046 18.5143 16.8046 18.4762 16.8046H16.3556C16.2857 16.8046 16.127 16.8046 16.0127 16.7919C15.3841 16.703 15.0984 16.1697 15.0984 15.7126C15.0984 15.1538 15.4794 14.7157 16.0254 14.6395C16.1079 14.6268 16.2095 14.6205 16.3429 14.6205H19.5365V15.3697H16.3937C16.273 15.3697 16.2159 15.3761 16.1651 15.3888C15.9873 15.4395 15.9238 15.5919 15.9238 15.7062C15.9238 15.8332 15.9873 15.9856 16.1587 16.03C16.1968 16.0427 16.3048 16.0491 16.4 16.0491H18.5778C18.6222 16.0491 18.7238 16.0491 18.8444 16.0618C19.4603 16.1189 19.7841 16.6649 19.7841 17.1729C19.7841 17.6681 19.473 18.2332 18.7937 18.2967C18.7556 18.303 18.5587 18.303 18.5333 18.303H15.2V17.5411H18.4762Z"
      fill="currentColor"
    />
    <path
      d="M31.181 14.5951V17.376L28.4317 14.5951H27.3714V18.3221H28.1968V15.5284L30.9587 18.3221H32V14.5951H31.181Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.7492 18.3221L23.073 14.5951H23.9619L26.2857 18.3221H25.2889L24.8762 17.6554H22.1651L21.7524 18.3221H20.7492ZM22.5905 16.9506H24.4508L23.5238 15.4522L22.5905 16.9506Z"
      fill="currentColor"
    />
    <path
      d="M27.4222 20.4427L27.4349 20.4046H31.0794V20.8427L29.5683 21.0205C28.6396 21.1279 28.4401 21.537 28.188 22.0539L28.1841 22.0618L28.1206 22.1888C25.9365 26.8427 21.181 29.8522 16.0127 29.8522C10.8444 29.8522 6.08889 26.8427 3.90477 22.1824L3.84127 22.0554L3.83743 22.0475C3.5853 21.5306 3.38576 21.1215 2.45715 21.0141L0.946036 20.8364V20.3983H4.57778L4.59048 20.4364C6.24762 25.2935 10.8381 28.557 16.0064 28.557C21.1746 28.557 25.7587 25.2999 27.4222 20.4427Z"
      fill="currentColor"
    />
  </svg>
);

export default Nissan;
