import { CustomIcon } from '@/components/atoms/icons/types';

const Karma: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M16.2522 32C25.0888 32 32.2522 24.8366 32.2522 16C32.2522 8.32256 26.8448 1.90809 19.6308 0.357378L17.9608 4.12071C23.7789 4.95009 28.2522 9.95265 28.2522 16C28.2522 22.6274 22.8796 28 16.2522 28C9.62478 28 4.2522 22.6274 4.2522 16C4.2522 9.74836 9.03279 4.61329 15.1377 4.05107L18.2314 0.121204C17.5829 0.0411996 16.9223 0 16.2522 0C7.41564 0 0.252197 7.16344 0.252197 16C0.252197 24.8366 7.41564 32 16.2522 32Z"
      fill="currentColor"
    />
    <path
      d="M24.2522 16C24.2522 20.4183 20.6705 24 16.2522 24C11.8339 24 8.2522 20.4183 8.2522 16C8.2522 11.5817 11.8339 8 16.2522 8C20.6705 8 24.2522 11.5817 24.2522 16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2522 26C21.775 26 26.2522 21.5228 26.2522 16C26.2522 10.4772 21.775 6 16.2522 6C10.7293 6 6.2522 10.4772 6.2522 16C6.2522 21.5228 10.7293 26 16.2522 26ZM16.2522 25C21.2228 25 25.2522 20.9706 25.2522 16C25.2522 11.0294 21.2228 7 16.2522 7C11.2816 7 7.2522 11.0294 7.2522 16C7.2522 20.9706 11.2816 25 16.2522 25Z"
      fill="currentColor"
    />
  </svg>
);

export default Karma;
