import { CustomIcon } from '@/components/atoms/icons/types';

const Arrival: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5728 14.4579V11.7421L12.9989 13.1L12.5715 12.4235L15.1454 11.0656L12.5715 9.70766L12.9989 9.03121L15.5728 10.3892V7.67334H16.4276V10.3892L19.0013 9.03121L19.4287 9.70766L16.8549 11.0656L19.4287 12.4235L19.0013 13.1L16.4276 11.7421V14.4579H15.5728Z"
      fill="currentColor"
    />
    <path
      d="M11.7889 24.3263H10.9387V21.5508H13.8775V22.277H11.7889V24.3263Z"
      fill="currentColor"
    />
    <path
      d="M6.04077 24.3263H6.89092V22.277H8.97955V21.5508H6.04077V24.3263Z"
      fill="currentColor"
    />
    <path
      d="M16.1634 21.5508V24.3263H15.1838V21.5508H16.1634Z"
      fill="currentColor"
    />
    <path
      d="M28.8979 24.3263H31.8367V23.6H29.7615V21.5508H28.8979V24.3263Z"
      fill="currentColor"
    />
    <path
      d="M22.3673 21.5508L20.5804 24.3263H19.5828L17.7959 21.5508H18.7907L20.0816 23.5558L21.3725 21.5508H22.3673Z"
      fill="currentColor"
    />
    <path
      d="M27.2653 24.3263L25.4784 21.5508H24.4808L22.6938 24.3263H23.6888L24.9796 22.3212L26.2705 24.3263H27.2653Z"
      fill="currentColor"
    />
    <path
      d="M2.94781 21.5508L4.73476 24.3263H3.73995L2.44899 22.3212L1.15814 24.3263H0.16333L1.95018 21.5508H2.94781Z"
      fill="currentColor"
    />
  </svg>
);

export default Arrival;
