import { CustomIcon } from '@/components/atoms/icons/types';

const Acura: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.9693 14.5155C31.6828 9.25431 29.8857 5.39966 27.4374 3.29003C24.7808 1.02411 19.8322 0.13856 16.0036 0.16462C12.175 0.13856 7.22641 1.02411 4.56977 3.29003C2.12155 5.39972 0.3244 9.25437 0.0379244 14.5155C-0.222552 19.464 0.845293 24.8553 3.65817 27.9026C6.36681 30.8978 11.7842 31.8354 15.9775 31.8354C20.1707 31.8614 25.588 30.8978 28.2967 27.9026C31.1097 24.8292 32.2035 19.4379 31.9693 14.5155ZM15.0919 1.49288C15.6128 1.46682 15.743 1.46682 15.9775 1.46682C16.2119 1.46682 16.316 1.46682 16.863 1.49288L16.8891 13.0048C16.7328 13.0048 15.2222 13.0048 15.0659 13.0048C15.0659 13.0048 15.0659 1.49288 15.0919 1.49288ZM2.14761 19.3597C1.99132 17.3803 1.3923 10.1658 4.25724 6.05066C5.48138 4.25357 7.27847 3.05549 9.10162 2.43042C11.628 1.54494 14.6492 1.49282 14.6492 1.49282C13.4772 4.38387 11.055 10.5826 10.5862 11.9629C10.0132 13.6558 9.51833 14.6195 9.0235 17.6668C8.7109 20.1671 8.16402 26.3919 8.00772 29.1006C4.62183 27.4858 2.59033 24.4125 2.14761 19.3597ZM15.9515 30.5071C13.5814 30.4811 10.6643 30.2466 8.34631 29.2569C9.12768 27.1733 12.8521 18.2138 14.9097 14.7238C15.0659 14.7238 16.863 14.7238 17.0193 14.7238C19.0508 18.2138 22.8013 27.1733 23.5827 29.2569C21.2385 30.2726 18.3476 30.4811 15.9515 30.5071ZM29.7814 19.3339C29.3386 24.4127 27.3071 27.4599 23.9212 29.1007C23.765 26.392 23.2181 20.1673 22.9055 17.6669C22.4106 14.5936 21.9158 13.656 21.3428 11.9631C20.874 10.5826 18.4518 4.38393 17.2798 1.49294C17.2798 1.49294 20.301 1.545 22.8273 2.43054C24.6505 3.05561 26.4476 4.27969 27.6717 6.05079C30.5367 10.1659 29.9376 17.3803 29.7814 19.3339Z"
      fill="currentColor"
    />
  </svg>
);

export default Acura;
