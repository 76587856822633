import { CustomIcon } from '@/components/atoms/icons/types';

const Dodge: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6898 7.49585L0 24.5043H7.19742L21.8577 7.50962L14.6898 7.49585ZM24.8321 7.49585L10.1423 24.5043H17.3397L32 7.50962L24.8321 7.49585Z"
      fill="currentColor"
    />
  </svg>
);

export default Dodge;
