import { CustomIcon } from '@/components/atoms/icons/types';

const MINI: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 22.4794C12.684 22.4794 9.99733 19.7914 9.99733 16.4767C9.99733 13.1607 12.684 10.4727 16 10.4727C19.316 10.4727 22.0027 13.1607 22.0027 16.4767C22.0027 19.7914 19.316 22.4794 16 22.4794ZM32 12.6967H21.9067C20.66 10.7354 18.4693 9.43408 15.972 9.43408C13.4773 9.43408 11.2867 10.7354 10.0387 12.6967H0L1.02133 13.7194H9.49867C9.34133 14.0887 9.21733 14.4741 9.12533 14.8714H2.17067L3.19333 15.8954H8.96667C8.952 16.0834 8.94267 16.2727 8.94267 16.4647C8.94267 16.6607 8.952 16.8554 8.968 17.0474H4.34267L5.364 18.0701H9.128C9.22133 18.4687 9.34667 18.8541 9.504 19.2234H6.51333L7.53467 20.2461H10.0467C11.296 22.1994 13.4827 23.4954 15.972 23.4954C18.464 23.4954 20.6493 22.1994 21.8987 20.2461H24.4653L25.4867 19.2234H22.4413C22.5987 18.8541 22.724 18.4687 22.8173 18.0701H26.636L27.6573 17.0474H22.9773C22.9933 16.8554 23.004 16.6607 23.004 16.4647C23.004 16.2727 22.9947 16.0834 22.9787 15.8954H28.8067L29.8293 14.8714H22.82C22.728 14.4741 22.604 14.0887 22.4467 13.7194H30.9787L32 12.6967Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9135 17.3491L12.0975 14.8838H11.1735V18.0505H11.8495V15.7025L12.5601 18.0505H13.2588L13.9721 15.7025V18.0505H14.6481V14.8838H13.7215L12.9135 17.3491Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7897 17.2033L17.5204 14.8833H16.7751V18.0513H17.4004V15.8313L18.6697 18.0686L19.4244 18.0513V14.8833H18.7977L18.7897 17.2033Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.384 18.049H16.0267V14.8823H15.384V18.049Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.156 18.049H20.7987V14.8823H20.156V18.049Z"
      fill="currentColor"
    />
  </svg>
);

export default MINI;
