import { CustomIcon } from '@/components/atoms/icons/types';

const Genesis: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3572 15.2528C13.2918 15.0532 13.3572 14.8468 13.5189 14.7401C13.5981 14.685 13.6875 14.6609 13.7942 14.6609C14.0385 14.6609 14.1073 14.6919 14.1073 14.7883C14.1073 14.8571 14.097 14.8708 14.0488 14.8468C14.0144 14.833 13.925 14.8158 13.8458 14.8055C13.7254 14.7951 13.6944 14.8055 13.629 14.8846C13.4673 15.0945 13.5877 15.418 13.8321 15.418C13.9284 15.418 13.9353 15.4077 13.9353 15.301C13.9353 15.2287 13.9146 15.1771 13.8837 15.1633C13.7804 15.1255 13.8355 15.0739 13.9869 15.0739H14.1418V15.2769C14.1418 15.4731 14.1383 15.4799 14.0351 15.5178C13.7288 15.6245 13.4363 15.5109 13.3572 15.2528Z"
      fill="currentColor"
    />
    <path
      d="M14.2691 14.685C14.2553 14.6954 14.2588 14.7229 14.2794 14.747C14.2966 14.7676 14.3173 14.9534 14.3207 15.1634L14.331 15.5385L14.6338 15.5488C14.916 15.5591 14.9332 15.5557 14.9332 15.4868C14.9332 15.4249 14.9126 15.418 14.7233 15.418H14.5168L14.5272 15.2872C14.5319 15.2293 14.5344 15.1978 14.5514 15.1796C14.5717 15.1578 14.6126 15.1552 14.7027 15.1496C14.8162 15.1427 14.8644 15.1255 14.8644 15.0877C14.8644 15.0532 14.8197 15.0395 14.6923 15.0395H14.5203V14.7986H14.7302C14.9091 14.7986 14.9367 14.7917 14.9195 14.7435C14.9091 14.7126 14.8988 14.6816 14.8988 14.6747C14.8988 14.6506 14.2932 14.6609 14.2691 14.685Z"
      fill="currentColor"
    />
    <path
      d="M15.064 14.7573C15.0433 14.7504 15.0365 14.7229 15.0502 14.7022C15.064 14.6782 15.1122 14.661 15.1603 14.661C15.2223 14.661 15.2946 14.7263 15.4563 14.9362L15.6731 15.2115L15.6834 14.9328C15.6937 14.6713 15.6972 14.6575 15.7694 14.6678C15.8417 14.6782 15.8451 14.6919 15.8451 15.1083C15.8451 15.5212 15.8417 15.5385 15.7694 15.5488C15.7144 15.5557 15.649 15.5006 15.4941 15.3113L15.2946 15.0601L15.2773 15.301C15.2601 15.5144 15.2533 15.5385 15.1844 15.5488C15.1053 15.5591 15.1053 15.5557 15.1053 15.1668C15.1053 14.8915 15.0915 14.7642 15.064 14.7573Z"
      fill="currentColor"
    />
    <path
      d="M16 14.7091C16 14.7366 16.0172 14.7711 16.0344 14.7814C16.0551 14.7951 16.0688 14.9638 16.0688 15.1806V15.5557H16.3613C16.6297 15.5557 16.6538 15.5488 16.6538 15.4868C16.6538 15.4249 16.6297 15.418 16.4646 15.418H16.2753V15.1427H16.4474C16.5781 15.1427 16.6194 15.1289 16.6194 15.0911C16.6194 15.0532 16.5781 15.0395 16.4474 15.0395H16.2753V14.7986H16.468C16.6297 14.7986 16.6573 14.7883 16.6469 14.7366C16.6366 14.6885 16.5884 14.6781 16.3166 14.6678C16.0551 14.6609 16 14.6678 16 14.7091Z"
      fill="currentColor"
    />
    <path
      d="M16.7915 14.8192C16.8087 14.7676 16.8603 14.7126 16.9016 14.6954C16.9945 14.6609 17.2423 14.6506 17.3214 14.6816C17.3524 14.6919 17.3765 14.7298 17.3765 14.7642C17.3765 14.8192 17.3558 14.8261 17.1872 14.8089C17.0117 14.7917 16.9979 14.7951 16.9979 14.8605C16.9979 14.9156 17.0461 14.9603 17.17 15.0223C17.4315 15.153 17.4935 15.3492 17.318 15.4868C17.2423 15.5453 17.1872 15.5557 17.0186 15.5488C16.819 15.5384 16.8087 15.535 16.7984 15.4455C16.788 15.3561 16.7915 15.3526 16.8913 15.387C16.9463 15.4077 17.0358 15.418 17.0909 15.4111C17.2457 15.3939 17.2147 15.2907 17.0151 15.184C16.8156 15.0739 16.7399 14.9534 16.7915 14.8192Z"
      fill="currentColor"
    />
    <path
      d="M17.5279 14.7022C17.5141 14.7229 17.521 14.7504 17.5416 14.7573C17.5692 14.7642 17.5829 14.8915 17.5829 15.1634V15.5557H17.7894V14.6609H17.6724C17.607 14.6609 17.5416 14.6781 17.5279 14.7022Z"
      fill="currentColor"
    />
    <path
      d="M18.2196 15.1737C17.9546 15.036 17.8961 14.8227 18.0957 14.7125C18.1886 14.6609 18.4295 14.6437 18.5258 14.6816C18.5568 14.6919 18.5809 14.7298 18.5809 14.7642C18.5809 14.8192 18.5602 14.8261 18.3916 14.8089C18.2196 14.7917 18.2024 14.7951 18.2024 14.8571C18.2024 14.8984 18.254 14.9534 18.3503 15.005C18.5706 15.1255 18.6153 15.1771 18.6153 15.301C18.6153 15.4765 18.4845 15.5625 18.223 15.5488C18.0234 15.5384 18.0131 15.535 18.0028 15.4455C17.9924 15.356 17.9959 15.3526 18.0957 15.387C18.2505 15.4421 18.3916 15.4145 18.3916 15.332C18.3916 15.2872 18.3366 15.2356 18.2196 15.1737Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8685 13.3843C14.011 13.0195 14.8919 12.7614 15.2705 12.6823C15.5974 12.6134 15.7454 12.5997 16.0688 12.61C16.5816 12.6238 16.9876 12.7167 18.3297 13.1331L18.5674 13.2065C19.2787 13.4263 19.5604 13.5133 19.848 13.5433C20.0365 13.563 20.2276 13.5582 20.5439 13.5502L20.7144 13.546C21.7709 13.5219 23.8975 13.4324 27.9409 13.2501L29.0774 13.1979C31.7064 13.0772 31.9956 13.0639 32 13.093C32.0005 13.0966 31.9967 13.1008 31.9924 13.1056C31.9895 13.1088 31.9864 13.1122 31.9843 13.1159C31.8053 13.4187 31.4509 13.663 31.0861 13.7284C30.7145 13.8006 29.4172 13.9245 25.2052 14.2996C24.0317 14.4063 22.7929 14.5199 22.4522 14.5577C20.6697 14.7539 20.3015 14.9259 19.7956 15.7931C19.2863 16.6706 19.0454 17.025 18.6291 17.4965C18.1163 18.078 17.1734 18.7904 16.3682 19.2033L16 19.3926L15.6318 19.2033C14.8162 18.7835 13.8837 18.078 13.3709 17.4965C12.9546 17.025 12.7137 16.6706 12.2044 15.7931C11.6985 14.9259 11.3303 14.7539 9.54778 14.5577C9.20711 14.5199 7.96828 14.4063 6.79484 14.2996C2.58283 13.9245 1.2855 13.8006 0.913857 13.7284C0.549092 13.663 0.19465 13.4187 0.0157085 13.1159C0.0135774 13.1122 0.010496 13.1088 0.00762582 13.1056C0.0033344 13.1008 -0.000484867 13.0966 5.02631e-05 13.093C0.00437891 13.0639 0.293622 13.0772 2.92255 13.1979L4.0591 13.2501C9.05914 13.4772 10.4666 13.5322 11.4404 13.5391L12.3696 13.5426L12.8685 13.3843ZM18.9147 13.9899C18.5293 13.8041 17.6277 13.5047 16.9016 13.3223C16.382 13.1916 15.9759 13.1365 15.7935 13.1675C15.2051 13.2741 14.0282 13.6079 13.4535 13.8316C12.7791 14.0931 12.5657 14.279 12.6104 14.5749C12.6448 14.8192 12.8961 15.5144 13.0888 15.9101C13.4432 16.6327 13.9043 17.235 14.5134 17.7683C15.1122 18.2914 15.9002 18.8145 16.0413 18.78C16.1549 18.7491 16.8947 18.2604 17.2216 17.9955C17.6277 17.6685 18.1542 17.1248 18.4157 16.7566C18.8424 16.1613 19.3724 14.9534 19.3827 14.5405C19.393 14.2859 19.3001 14.1792 18.9147 13.9899Z"
      fill="currentColor"
    />
    <path
      d="M1.00333 14.1723C1.01021 14.1895 1.13065 14.3065 1.27174 14.4373C2.29033 15.3664 3.03363 15.6486 4.91939 15.8103C5.77969 15.886 6.95313 15.9583 8.37778 16.0202C9.05914 16.0512 9.69919 16.0925 9.80243 16.1131C9.92975 16.1372 10.0227 16.1819 10.1121 16.2611C10.181 16.323 10.2257 16.385 10.2154 16.3953C10.1947 16.4159 7.71363 16.3747 6.31307 16.3299C5.8726 16.3162 5.66957 16.3196 5.69366 16.3437C5.77281 16.4228 7.4934 16.9046 8.44316 17.1145L8.92837 17.2212L10.2704 17.2246C11.7158 17.228 11.7158 17.2281 11.8976 17.4201L11.9016 17.4242L11.9807 17.5102L11.8327 17.5309C11.6641 17.5515 10.7178 17.6204 10.5939 17.6204C10.5457 17.6204 10.5285 17.6341 10.5492 17.6513C10.587 17.6926 11.8086 18.0264 12.3523 18.1503C12.8582 18.2604 13.7288 18.4222 13.746 18.405C13.7529 18.3981 13.6738 18.3224 13.5671 18.2398C13.1232 17.8853 12.6208 17.2762 11.9704 16.3127C11.6331 15.8103 11.289 15.4352 11.0516 15.3044C10.7316 15.1324 10.0055 15.0051 8.46381 14.8502C4.46516 14.4579 1.19603 14.1448 1.04806 14.1448C1.01709 14.1448 0.996446 14.1585 1.00333 14.1723Z"
      fill="currentColor"
    />
    <path
      d="M26.53 14.5577C27.5039 14.4614 28.8528 14.3306 29.5238 14.2686C30.8659 14.1413 31.0139 14.131 30.9967 14.1723C30.9932 14.1895 30.8693 14.3065 30.7283 14.4373C29.7097 15.3664 28.9629 15.6486 27.0806 15.8103C26.2169 15.886 25.0469 15.9583 23.6222 16.0202C22.9409 16.0512 22.3008 16.0925 22.1976 16.1131C22.0702 16.1372 21.9773 16.1819 21.8879 16.2611C21.819 16.323 21.7743 16.385 21.7846 16.3953C21.8053 16.4159 24.3552 16.3712 25.7041 16.3299C26.1308 16.3162 26.3304 16.3196 26.3063 16.3437C26.2272 16.4228 24.5032 16.9046 23.5568 17.1145L23.0716 17.2212L21.7296 17.2246C20.2842 17.228 20.2842 17.2281 20.1024 17.4201L20.0984 17.4242L20.0193 17.5102L20.1673 17.5309C20.3359 17.5515 21.2822 17.6204 21.4061 17.6204C21.4543 17.6204 21.4715 17.6341 21.4508 17.6513C21.413 17.6926 20.1914 18.0264 19.6477 18.1503C19.1418 18.2604 18.2712 18.4222 18.254 18.405C18.2471 18.3981 18.3262 18.3224 18.4329 18.2398C18.8768 17.8853 19.3792 17.2762 20.0296 16.3127C20.3669 15.8103 20.711 15.4352 20.9484 15.3044C21.2788 15.1255 21.998 15.0016 23.7082 14.8364C24.0703 14.7998 24.7037 14.7375 25.3657 14.6724C25.7608 14.6336 26.1661 14.5937 26.53 14.5577Z"
      fill="currentColor"
    />
  </svg>
);

export default Genesis;
