import { CustomIcon } from '@/components/atoms/icons/types';

const Mitsubishi: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7945 20.7724L16.7492 20.7707L21.7693 29.4272H31.811L26.7971 20.7724H26.7945ZM16.7872 3.50201L11.7706 12.1887L16.7396 20.7614L16.7431 20.7554L16.7491 20.764L21.7752 12.1023L16.788 3.50201H16.7872ZM6.81032 20.7707L1.81104 29.4272H11.775L16.7354 20.7707H6.81032Z"
      fill="currentColor"
    />
  </svg>
);

export default Mitsubishi;
