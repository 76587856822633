/**
 * Exporting as lowercase with no hyphens, underscores, or spaces so we can
 * easily normalize and search by make name.
 */
export { default as abarth } from './Abarth';
export { default as acura } from './Acura';
export { default as afeela } from './Afeela';
export { default as alfaromeo } from './AlfaRomeo';
export { default as apple } from './Apple';
export { default as arrival } from './Arrival';
export { default as astonmartin } from './AstonMartin';
export { default as audi } from './Audi';
export { default as bentley } from './Bentley';
export { default as bmw } from './BMW';
export { default as buick } from './Buick';
export { default as byd } from './BYD';
export { default as cadillac } from './Cadillac';
export { default as canoo } from './Canoo';
export { default as chevrolet } from './Chevrolet';
export { default as chrysler } from './Chrysler';
export { default as cruise } from './Cruise';
export { default as dacia } from './Dacia';
export { default as dodge } from './Dodge';
export { default as faraday } from './Faraday';
export { default as ferrari } from './Ferrari';
export { default as fiat } from './Fiat';
export { default as fisker } from './Fisker';
export { default as ford } from './Ford';
export { default as genesis } from './Genesis';
export { default as gmc } from './GMC';
export { default as honda } from './Honda';
export { default as hyundai } from './Hyundai';
export { default as infiniti } from './Infiniti';
export { default as jaguar } from './Jaguar';
export { default as jeep } from './Jeep';
export { default as karma } from './Karma';
export { default as kia } from './Kia';
export { default as lamborghini } from './Lamborghini';
export { default as landrover } from './LandRover';
export { default as lexus } from './Lexus';
export { default as lincoln } from './Lincoln';
export { default as lordstown } from './Lordstown';
export { default as lotus } from './Lotus';
export { default as lucid } from './Lucid';
export { default as maserati } from './Maserati';
export { default as mazda } from './Mazda';
export { default as mclaren } from './McLaren';
export { default as mercedesbenz } from './MercedesBenz';
export { default as mini } from './MINI';
export { default as mitsubishi } from './Mitsubishi';
export { default as nissan } from './Nissan';
export { default as nuride } from './NuRide';
export { default as polestar } from './Polestar';
export { default as porsche } from './Porsche';
export { default as ram } from './RAM';
export { default as rimac } from './Rimac';
export { default as rivian } from './Rivian';
export { default as rollsroyce } from './RollsRoyce';
export { default as smart } from './Smart';
export { default as ssangyong } from './Ssangyong';
export { default as subaru } from './Subaru';
export { default as suzuki } from './Suzuki';
export { default as tesla } from './Tesla';
export { default as toyota } from './Toyota';
export { default as vinfast } from './VinFast';
export { default as volkswagen } from './Volkswagen';
export { default as volvo } from './Volvo';
