import { CustomIcon } from '@/components/atoms/icons/types';

const Tesla: CustomIcon = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.5876 7.48043C45.0906 4.21289 37.7671 3.17417 31.3689 3.2231H31.368C24.9696 3.17417 17.6449 4.21289 10.1492 7.48043C9.25606 5.86164 8.8905 4.87983 8.8905 4.87983C17.0848 1.63764 24.7593 0.527834 31.368 0.5C37.977 0.527834 45.6519 1.63746 53.8472 4.87983C53.8472 4.87983 53.5896 5.67691 52.5876 7.48043ZM31.3713 45.2645L37.6607 9.89276C43.6556 9.89276 45.5465 10.5501 45.8196 13.2333C45.8196 13.2333 49.8411 11.7339 51.8694 8.68846C43.9543 5.02079 36.0015 4.85538 36.0015 4.85538L31.3664 10.5009L26.7307 4.85449C26.7307 4.85449 18.7777 5.02008 10.8637 8.68775C12.8902 11.7332 16.9134 13.2326 16.9134 13.2326C17.188 10.5493 19.0766 9.89188 25.0313 9.88762L31.3713 45.2645ZM19.1246 59.246H23.9133C24.5795 59.1132 25.0747 58.5266 25.2135 57.958H17.8244C17.9624 58.5266 18.4648 59.1132 19.1246 59.246ZM54.7714 59.2513H49.9811C49.3218 59.1191 48.8197 58.5321 48.6816 57.9639H56.0711C55.9315 58.5319 55.4367 59.1191 54.7714 59.2513ZM48.8742 64.3925V60.5078L55.8989 60.5145V64.3925H54.654V61.8061H50.12V64.3925H48.8742ZM45.1012 64.3948C45.7293 64.1285 46.0672 63.6679 46.1966 63.129H40.6091L40.6127 57.9668L39.3602 57.9703V64.3948H45.1012ZM34.4699 59.2339H30.0459V60.503H35.7935V64.3934H28.8058L28.4403 64.4001C28.7394 63.7731 29.3389 63.2983 30.0459 63.1012L34.5577 63.0976V61.7653H28.8058V57.9687H35.8334C35.6969 58.532 35.1369 59.0401 34.4699 59.2339ZM23.9133 61.7855H19.1246C18.4648 61.6541 17.9624 61.0671 17.8244 60.498H25.2135C25.0747 61.067 24.5795 61.6541 23.9133 61.7855ZM19.1246 64.3941H23.9133C24.5795 64.2616 25.0747 63.6746 25.2135 63.1064H17.8244C17.9624 63.6748 18.4648 64.2616 19.1246 64.3941ZM7.96364 59.2534C7.2967 59.1097 6.81058 58.5358 6.66663 57.9743V57.9619H14.7643V57.9743C14.6228 58.5358 14.1472 59.0797 13.4728 59.2534H11.4553L11.3409 59.294V64.3805H10.0822V59.294L9.97938 59.2534H7.96364Z"
      fill="currentColor"
    />
  </svg>
);

export default Tesla;
