import * as MakeIcons from './makes-grayscale';

type MakeIcon = keyof typeof MakeIcons;

const isMakeIcon = (make: string): make is MakeIcon => {
  return make in MakeIcons;
};

interface MakeIconMonoProps {
  make: string;
  className?: string;
}

export const MakeIconMono: React.FC<MakeIconMonoProps> = ({
  make,
  className,
}) => {
  const normalizedMake = make
    .toLowerCase()
    .replaceAll(' ', '')
    .replaceAll('-', '');
  if (isMakeIcon(normalizedMake)) {
    const Icon = MakeIcons[normalizedMake];

    return <Icon className={className} />;
  }

  return null;
};
