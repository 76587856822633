import { CustomIcon } from '@/components/atoms/icons/types';

const McLaren: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.9001 7.27399C23.5101 7.27399 18.6449 8.27842 13.3716 10.1931C8.69615 11.9037 4.21416 14.1026 0 16.7533C4.04149 15.2524 8.30774 14.4458 12.6182 14.3678C18.5821 14.3678 22.9137 16.6592 16.6046 24.726C35.2181 12.5787 34.6845 7.27399 26.9001 7.27399Z"
      fill="currentColor"
    />
  </svg>
);

export default McLaren;
