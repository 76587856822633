import { CustomIcon } from '@/components/atoms/icons/types';

const Smart: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.971 10.8041C19.3874 9.66067 17.2755 9.04443 15.6919 9.04443C11.2931 9.04443 8.12586 12.2117 8.12586 16.2583C8.12586 20.3059 11.2931 23.5612 15.6919 23.5612C17.2755 23.5612 19.3874 22.857 20.971 21.7135V28.7513C19.3874 29.8077 17.4516 30.3349 15.0766 30.3349C7.33355 30.3349 1 24.0884 1 16.2583C1 8.51721 7.33355 2.18268 15.0766 2.18268C17.4516 2.18268 19.3874 2.79794 20.971 3.76631V10.8041ZM31.0001 16.2582C29.8566 12.8279 25.3698 6.58136 22.2906 4.55756V27.9599C25.3698 25.9361 29.8566 19.7776 31.0001 16.2582Z"
      fill="currentColor"
    />
  </svg>
);

export default Smart;
