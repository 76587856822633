import { CustomIcon } from '@/components/atoms/icons/types';

const Toyota: CustomIcon = ({ className }) => (
  <svg
    className={className}
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0599 25.036C13.4873 24.0504 12.3279 20.6511 12.3279 16.6111C12.3279 16.4624 12.3279 16.3166 12.3293 16.1714L12.3314 16.1728C7.95914 15.4852 4.79721 13.6013 4.71694 11.3726L4.70298 11.3992C3.63224 12.7295 3.0208 14.2679 3.0208 15.9068C3.0208 20.73 8.31512 24.6807 15.0306 25.0311L15.0585 25.036H15.0599ZM1 16.2587C1 10.8631 7.71475 6.48669 16 6.48669C24.2852 6.48669 31 10.8631 31 16.2587C31 21.6542 24.2852 26.0307 16 26.0307C7.71475 26.0307 1 21.6542 1 16.2587ZM16 7.89656C17.5985 7.89656 18.9596 10.3228 19.4635 13.7081L19.4656 13.7025C22.5773 13.2181 24.755 12.1006 24.755 10.8009C24.755 9.05594 20.8351 7.64598 16 7.64598C11.165 7.64598 7.24504 9.05594 7.24504 10.8009C7.24504 12.1013 9.4228 13.2188 12.5345 13.7032L12.5366 13.7088C13.0405 10.3235 14.4002 7.89726 16 7.89726V7.89656ZM16.9681 25.0304C23.6829 24.6814 28.9772 20.7293 28.9772 15.9061C28.9772 14.2672 28.3657 12.7288 27.295 11.3985L27.2811 11.3726C27.2008 13.5993 24.0402 15.4838 19.6659 16.1721L19.6673 16.17C19.6701 16.3166 19.6722 16.4631 19.6722 16.6097C19.6722 20.6511 18.5135 24.0504 16.943 25.0346L16.9709 25.0304H16.9681ZM15.9999 13.9603C16.7168 13.9603 17.4127 13.9289 18.08 13.8695L18.0786 13.8709C17.747 11.7001 16.9408 10.1673 15.9999 10.1673C15.0576 10.1673 14.2535 11.6988 13.9213 13.8681V13.8667C14.5872 13.9254 15.2838 13.9561 15.9999 13.9561V13.9603ZM15.9999 21.9954C17.2039 21.9954 18.186 19.4896 18.2384 16.3437L18.2454 16.3458C17.5194 16.4121 16.7684 16.4477 15.9999 16.4477C15.2321 16.4477 14.4803 16.4128 13.7551 16.3451L13.7621 16.3423C13.8138 19.4861 14.7951 21.992 15.9999 21.992V21.9954Z"
      fill="currentColor"
    />
  </svg>
);

export default Toyota;
